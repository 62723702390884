import moment from 'moment'
import React, { useState } from 'react'
import 'react-day-picker/lib/style.css'
import { useTranslation } from 'react-i18next'
import CalendarIcon from '../icons/CalendarIcon'
import FiltersIcon from '../icons/FiltersIcon'
import Outter from '../outter/Outter'
import FilterDrawer from './FilterDrawer'
import './Filters.less'

const Filters = (props) => {
  const { filter } = props

  const { t } = useTranslation()

  const [visibleFilter, setVisibleFilter] = useState(false)

  const onClose = () => {
    document.body.style.overflow = 'scroll'
    setVisibleFilter(false)
  }

  const getYear = (date) => {
    return moment().format('yyyy') !== date.format('yyyy') ? date.format('yyyy') : ''
  }

  const getDateLine1 = () => {
    let strFrom = ''
    let strTo = ''
    if (filter) {
      if (filter.dateFrom) {
        strFrom = `${t(filter.dateFrom.format('MMM'))} ${filter.dateFrom.format(
          'D'
        )} ${getYear(filter.dateFrom)}`
      }
      if (filter.dateTo) {
        strTo = `${t(filter.dateTo.format('MMM'))} ${filter.dateTo.format(
          'D'
        )} ${getYear(filter.dateTo)}`
      }
    }
    if (strFrom === strTo) {
      return strFrom
    }
    return `${strFrom} - ${strTo}`
  }

  const getDateLine2 = () => {
    let strFrom = ''
    let strTo = ''
    if (filter) {
      if (filter.dateFromP) {
        strFrom = `${t(filter.dateFromP.format('MMM'))} ${filter.dateFromP.format(
          'D'
        )} ${getYear(filter.dateFromP)}`
      }
      if (filter.dateToP) {
        strTo = `${t(filter.dateToP.format('MMM'))} ${filter.dateToP.format(
          'D'
        )} ${getYear(filter.dateTo)}`
      }
    }
    if (strFrom === strTo) {
      return `${t('VS')} ${strFrom}`
    }
    return `${t('VS')} ${strFrom} - ${strTo}`
  }

  return (
    <div className='filters'>
      <Outter>
        <div
          className='filters__appMode'
          onClick={() => {
            setVisibleFilter(true)
            document.body.style.overflow = 'hidden'
          }}
        >
          <div className='filters__appMode--calendar'>
            <CalendarIcon />
            <div className='filters__appMode--calendar--date'>
              <div>{getDateLine1()}</div>
              <div className='date-wrapper'>{getDateLine2()}</div>
            </div>
          </div>
          <div className='filters__appMode--filters'>
            <FiltersIcon />
          </div>
        </div>
      </Outter>
      {Boolean(props.screenSize === 'mobile') && (
        <FilterDrawer
          {...props}
          placement={'bottom'}
          height={'90%'}
          visible={visibleFilter}
          onClose={onClose}
          filter={filter}
        />
      )}
    </div>
  )
}

export default Filters
