import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import repetedDataActions from '../../actions/repetedDataActions'
import BarChart from '../../components/barChart/BarChart'
import Outter from '../../components/outter/Outter'
import { notifyError } from '../../utils/notifications'
import useIsMounted from '@utilityjs/use-is-mounted'

const PaymentBarChartSection = (props) => {
  const { filter } = props

  const { t } = useTranslation()
  const isMounted = useIsMounted()

  const [listData, setListData] = useState([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    const fill = async () => {
      if (Object.keys(filter).length > 0) {
        setloading(true)
        const result = await repetedDataActions.saleReturnPaymentByMonthList(filter)
        if (isMounted()) {
          if (result.success) {
            setListData(result.data)
          } else {
            notifyError(result.errMsg)
          }
          setloading(false)
        }
      }
    }
    fill()
  }, [filter, isMounted])

  return (
    <Outter>
      <div className='outter-title'>{t('Last 12 months')}</div>
      <div className='chart-info'>
        <div className='statsCard__amountHolder--amount--label label-wrapper'>
          <div className='value-name value-name-color3'></div>
          {t('SALES')}
        </div>
        <div className='statsCard__amountHolder--amount--label label-wrapper'>
          <div className=' value-name value-name-color'></div>
          {t('ORDERS')}
        </div>
        <div className='statsCard__amountHolder--amount--label label-wrapper'>
          <div className='value-name '></div>
          {t('RETURNS')}
        </div>
      </div>
      {!loading && (
        <BarChart
          {...props}
          labels={listData.map((it) => {
            return t(it.monthName)
          })}
          dataSets={[
            {
              label: t('SALES'),
              data: listData.map((it) => {
                return it.saleCount - it.returnCount
              }),
              backgroundColor: '#99C910',
              borderColor: '#99C910',
              borderRadius: '50',
            },
            {
              label: t('ORDERS'),
              data: listData.map((it) => {
                return it.paymentCount
              }),
              backgroundColor: '#5861f7',
              borderColor: '#5861f7',
              borderRadius: '50',
            },
            {
              label: t('RETURNS'),
              data: listData.map((it) => {
                return it.paymentCount
              }),
              backgroundColor: '#ff9e34',
              borderColor: '#ff9e34',
              borderRadius: '50',
            },
          ]}
        />
      )}
    </Outter>
  )
}

export default PaymentBarChartSection
