import React from "react";

const SettingsIcon = (props) => {
  const { color } = props;
  return (
    <svg
    width='49'
      height='49'
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
        <path 
            d="M24.342 30.004C21.133 30.004 18.5373 27.504 18.5373 24.364C18.5373 21.224 21.133 18.704 24.342 18.704C27.5509 18.704 30.0854 21.224 30.0854 24.364C30.0854 27.504 27.5509 30.004 24.342 30.004Z" 
            fill={color}
        />
        <path 
            opacity="0.4" 
            d="M42.7781 29.0841C42.3898 28.4841 41.8379 27.8841 41.1225 27.5041C40.5502 27.2241 40.1823 26.7641 39.8553 26.2241C38.8129 24.5041 39.4261 22.2441 41.1634 21.2241C43.2073 20.0841 43.8614 17.5441 42.6759 15.5641L41.3065 13.2041C40.1415 11.2241 37.5866 10.5241 35.5631 11.6841C33.7644 12.6441 31.4548 12.0041 30.4124 10.3041C30.0854 9.74406 29.9014 9.14406 29.9423 8.54405C30.0036 7.76406 29.7584 7.02406 29.3905 6.42406C28.6342 5.18406 27.2648 4.34406 25.7523 4.34406H22.8704C21.3783 4.38406 20.0089 5.18406 19.2526 6.42406C18.8643 7.02406 18.6395 7.76406 18.6803 8.54405C18.7212 9.14406 18.5373 9.74406 18.2102 10.3041C17.1679 12.0041 14.8582 12.6441 13.08 11.6841C11.0361 10.5241 8.50165 11.2241 7.31618 13.2041L5.94676 15.5641C4.78173 17.5441 5.43578 20.0841 7.45926 21.2241C9.19658 22.2441 9.80976 24.5041 8.7878 26.2241C8.44033 26.7641 8.07243 27.2241 7.50013 27.5041C6.8052 27.8841 6.19203 28.4841 5.865 29.0841C5.10875 30.3241 5.14963 31.8841 5.90588 33.1841L7.31618 35.5841C8.07243 36.8641 9.48273 37.6641 10.9543 37.6641C11.6493 37.6641 12.4668 37.4641 13.1209 37.0641C13.6319 36.7241 14.2451 36.6041 14.9195 36.6041C16.943 36.6041 18.6395 38.2641 18.6803 40.2441C18.6803 42.5441 20.5607 44.3441 22.9317 44.3441H25.7114C28.0619 44.3441 29.9423 42.5441 29.9423 40.2441C30.0036 38.2641 31.7001 36.6041 33.7236 36.6041C34.3776 36.6041 34.9908 36.7241 35.5222 37.0641C36.1763 37.4641 36.9734 37.6641 37.6888 37.6641C39.1399 37.6641 40.5502 36.8641 41.3065 35.5841L42.7372 33.1841C43.473 31.8441 43.5344 30.3241 42.7781 29.0841Z" 
            fill={color}
        />
    </svg>
  );
};

export default SettingsIcon;
SettingsIcon.defaultProps = {
  color: "a7a6b9;",
};
