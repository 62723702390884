import React from 'react'
import { Line } from 'react-chartjs-2'

const BasicChart = (props) => {
  const { data, screenSize } = props

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          borderDash: [5, 7],
          drawBorder: false,
          color: 'rgba(118, 118, 118, 0.3)',
        },
        ticks: {
          callback(value, index) {
            if (index % 2 === 0) return ''
            return value
          },
        },
      },
    },
  }

  return (
    <Line
      options={options}
      data={data}
      title='Chart'
      height={screenSize === 'mobile' ? '150' : '50'}
    />
  )
}

export default BasicChart