import React from 'react'
import { useTranslation } from 'react-i18next'
import CompareBlock from '../../../components/compareBlock/CompareBlock'
import UtilNumber from '../../../utils/utilNumber'
import './PaymentListItem.less'

const PaymentListItem = (props) => {

  const { item } = props

  const { t } = useTranslation();

  return (
    <div className='order-list-card'>
      <div className='order-list-wrapper'>
        <div className='order-list-content'>
          <h1>{t(item.name)}</h1>
        </div>
        <div className='order-list-content order-list-info'>
          <div className='content-info'>
            <h5>{UtilNumber.formatKMB(item.count, t)}</h5>
          </div>
          <div className='content-info payment-info'>
            <h5>
              {t(item.currencyName)}
              {UtilNumber.formatKMB(item.totalAmount, t)}
            </h5>
            {item && item.hasOwnProperty('p') && (
              <CompareBlock
                value={item.totalAmount}
                oldValue={item.p.totalAmount}
                currency={item.currencyName}
              />
            )}
          </div>

        </div>
      </div>
    </div>
  )
}

export default PaymentListItem