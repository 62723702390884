import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import repetedDataActions from '../../actions/repetedDataActions'
import BasicChart from '../../components/basicChart/BasicChart'
import Outter from '../../components/outter/Outter'
import { notifyError } from '../../utils/notifications'
import useIsMounted from '@utilityjs/use-is-mounted'

const OrderBasicChartSection = (props) => {
  const { t } = useTranslation()
  const isMounted = useIsMounted()

  const [listData, setlistData] = useState([])

  const chartData = (canvas) => {
    const ctx = canvas.getContext('2d')

    //1. Using gradient background.
    let gradientOne = ctx.createLinearGradient(0, 0, 0, canvas.height)
    gradientOne.addColorStop(0, 'rgba(103, 65, 250, 0.3)')
    // gradientOne.addColorStop(0.5, '103, 65, 250, 0.3)')
    gradientOne.addColorStop(1, 'rgba(1, 168, 241, 0.05)')

    let gradientTwo = ctx.createLinearGradient(0, 0, 0, canvas.height)
    gradientTwo.addColorStop(0, 'rgba(255, 122, 0, 0.3)')
    // gradientTwo.addColorStop(0.5, 'rgba(255, 122, 0, 0.2)')
    gradientTwo.addColorStop(1, 'rgba(255, 242, 129, 0.1)')

    let gradient3 = ctx.createLinearGradient(0, 0, 0, canvas.height)
    gradient3.addColorStop(0, 'rgba(255, 122, 0, 0.3)')
    // gradient3.addColorStop(0.5, 'rgba(255, 122, 0, 0.2)')
    gradient3.addColorStop(1, 'rgba(255, 242, 129, 0.1)')

    const result = {
      labels: listData?.map((it) => {
        return t(it.monthName)
      }),
      datasets: [
        {
          label: t('ORDERS'),
          data: listData?.map((it) => {
            return it.saleTotalAmount
          }),
          fill: true,
          backgroundColor: gradientOne,
          borderColor: '#5861f7',
          tension: 0.3,
        },
        {
          label: t('RETURNS'),
          data: listData?.map((it) => {
            return it.returnTotalAmount
          }),
          fill: true,
          backgroundColor: gradientTwo,
          borderColor: '#ff9e34',
          tension: 0.3,
        },
        {
          label: t('RETURNdd'),
          data: listData?.map((it) => {
            return it.p?.saleTotalAmount
          }),
          borderColor: '#5861f7',
          borderDash: [4, 8],
          tension: 0.3,
          borderWidth: 2,
        },
        {
          data: listData?.map((it) => {
            return it.p?.returnTotalAmount
          }),
          fill: true,
          backgroundColor: gradient3,
          borderColor: '#ff9e34',
          tension: 0.3,
          borderWidth: 2,
        },
      ],
    }
    return result
  }

  useEffect(() => {
    const fill = async () => {
      const result = await repetedDataActions.saleReturnPaymentByMonthList()
      if (isMounted()) {
        if (result.success) {
          setlistData(result.data)
        } else {
          notifyError(result.errMsg)
        }
      }
    }
    fill()
  }, [isMounted])

  return (
    <Outter>
      <div className='outter-title'>{t('Last 12 months')}</div>
      <div className='chart-info'>
        <div className='statsCard__amountHolder--amount--label label-wrapper'>
          <div className=' value-name value-name-color' />
          {t('ORDERS')}
        </div>
        <div className='statsCard__amountHolder--amount--label label-wrapper'>
          <div className='value-name ' />
          {t('RETURNS')}
        </div>
      </div>
      <BasicChart data={chartData} {...props} />
    </Outter>
  )
}

export default OrderBasicChartSection
