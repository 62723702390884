import { fetchFromUrl } from "../utils/urlHelper";
import prepareRequestParams from "./prepareRequestParams";
const customerCount = "/customer_count";
const routCount = "/rout_count";
const customerCountCountry = "/customer_count_by_country_list"
const customerCountRegion = "/customer_count_by_region_list"
const customerCountCity = "/customer_count_by_city_list"
const customerCountDistrict = "/customer_count_by_district_list"
const customerRoutCountList = "/rout_customer_count_list"
const customerCountByMonthList = "/customer_count_by_month_list"
const URL_CUSTOMER_LIST = "/customer_list"

const fs = {
    routCustomerCount: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(routCount, params)
        return result
    },
    countCustomer: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(customerCount, params)
        return result
    },
    customerCountCountryList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(customerCountCountry, params)
        return result
    },
    customerCountRegionList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(customerCountRegion, params)
        return result
    },
    customerCountDistrictList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(customerCountDistrict, params)
        return result
    },
    customerCountCityList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(customerCountCity, params)
        return result
    },
    customerRoutCountList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(customerRoutCountList, params)
        return result
    },
    customerCountByMonthList: async () => {
        const params = []
        const result = await fetchFromUrl(customerCountByMonthList, params)
        return result
    },
    getCustomerList: async (idsRoute) => {
        const urlParams = []
        if (idsRoute && idsRoute.length) {
            urlParams.push({ key: 'idsRoute', value: idsRoute });
        }
        const result = await fetchFromUrl(URL_CUSTOMER_LIST, urlParams);
        return result;
    },
}

const loc = {
    getCustomerListOptions: async (idsRoute) => {
        let result = await fs.getCustomerList(idsRoute);
        if (result.success) {
            return result.data.map((oo) => {
                return { label: oo.name.trim(), value: oo.idCustomer }
            })
        } else {
            return [];
        }
    }
}

const customerActions = Object.assign(fs, loc);

export default customerActions