import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        whitelist: ["en", "hy"],
        checkWhitelist: true,
        fallbackLng: "en",
        debug: false,
        interpolation: {
            escapeValue: false
        },

        detection: {
            order: ["localStorage"],
            checkWhitelist: true,
            caches: ['localStorage'],
            excludeCacheFor: ['cimode'],
            lookupLocalStorage: 'lng',
        }
    });

i18n.on('languageChanged', (lang) => {
    const html = document.querySelector('html');
    html && html.setAttribute('lang', lang);
});

export default i18n;