import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomerActions from '../../../actions/customerActions'
import { selectoptions } from '../../../actions/userActions'
import AppList from '../../../components/appList/AppList'
import { notifyError } from '../../../utils/notifications'
import CustomerAreaListItem from './customerAreaListItem/CustomerAreaListItem'
import useIsMounted from '@utilityjs/use-is-mounted'

const CustomerAreaListSection = (props) => {
  const { filter, selectedAreaOption } = props

  const { t } = useTranslation()
  const isMounted = useIsMounted()

  const [areaList, setAreaList] = useState([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    const fill = async () => {
      if (Object.keys(filter).length > 0) {
        let result
        setloading(true)
        switch (selectedAreaOption) {
          case selectoptions.COUNTRY:
            result = await CustomerActions.customerCountCountryList(filter)
            break
          case selectoptions.REGION:
            result = await CustomerActions.customerCountRegionList(filter)
            break
          case selectoptions.CITY:
            result = await CustomerActions.customerCountCityList(filter)
            break
          case selectoptions.DISTRICT:
            result = await CustomerActions.customerCountDistrictList(filter)
            break
          default:
            break
        }
        if (isMounted()) {
          if (result.success) {
            setAreaList(result.data)
          } else {
            notifyError(result.errMsg)
          }
          setloading(false)
        }
      }
    }
    fill()
  }, [selectedAreaOption, filter, isMounted])

  return (
    <AppList
      className='customer-grid'
      dataSource={areaList}
      loading={loading}
      header={
        <div className='order-list-label'>
          {[t('NAME'), t('COUNT')].map((oo, index) => {
            return <div key={index}>{oo}</div>
          })}
        </div>
      }
      renderItem={(item, index) => {
        return <CustomerAreaListItem item={item} />
      }}
    />
  )
}

export default CustomerAreaListSection
