import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomerActions from '../../../actions/customerActions'
import AppList from '../../../components/appList/AppList'
import Outter from '../../../components/outter/Outter'
import { notifyError } from '../../../utils/notifications'
import CustomerRouteListItem from './customerRouteListItem/CustomerRouteListItem'
import useIsMounted from '@utilityjs/use-is-mounted'

const CustomerRouteListSection = (props) => {
  const { filter } = props

  const { t } = useTranslation()
  const isMounted = useIsMounted()

  const [dataList, setdataList] = useState([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    const fill = async () => {
      if (Object.keys(filter).length > 0) {
        setloading(true)
        let result = await CustomerActions.customerRoutCountList(filter)
        if (isMounted()) {
          if (result.success) {
            setdataList(result.data)
          } else {
            notifyError(result.errMsg)
          }
          setloading(false)
        }
      }
    }
    fill()
  }, [filter, isMounted])

  return (
    <Outter>
      <div className='outter-title'>{t('ROUTS')}</div>
      <AppList
        className='customer-grid'
        dataSource={dataList}
        loading={loading}
        header={
          <div className='order-list-label'>
            {[t('NAME'), t('CUSTOMERS')].map((oo, index) => {
              return <div key={index}>{oo}</div>
            })}
          </div>
        }
        renderItem={(item) => {
          return <CustomerRouteListItem item={item} />
        }}
      />
    </Outter>
  )
}

export default CustomerRouteListSection
