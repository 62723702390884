import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import userActions from '../actions/userActions';
import { ApiStorageAction } from '../redux/apiStorageAction';
import useIsMounted from "@utilityjs/use-is-mounted";

const MagicLogin = () => {

    let { _token } = useParams();
    const history = useHistory();

    const isMounted = useIsMounted();

    useEffect(() => {
        const fill = async () => {
            if (_token) {
                let result = await userActions.loginMagic(_token);
                if (isMounted()) {
                    if (result.success) {
                        ApiStorageAction.setAccessToken(result.data.ACCESS_TOKEN)
                        history.push('/home')
                    } else {
                        ApiStorageAction.setAccessToken('')
                        history.push('/login')
                    }
                }
            }
        }
        fill();
    }, [_token, history, isMounted])

    return (
        <div>
            Loading...
        </div>
    );
};

export default MagicLogin;