import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserActions from "../../actions/userActions";
import SelectInput from "../selectInput/SelectInput";
import useIsMounted from "@utilityjs/use-is-mounted";

const SelectDate = (props) => {
  const { onChange, value, className, label, onRangeChange } = props;

  const [options, setOptions] = useState([]);

  const { t } = useTranslation();
  const isMounted = useIsMounted();

  useEffect(() => {
    let list = UserActions.getRangeOptions(t);
    if (isMounted()) {
      setOptions(
        list.map((item) => {
          return { ...item, label: t(item.label) };
        })
      );
    }
  }, [t, isMounted]);

  return (
    <SelectInput
      label={label}
      className={className}
      options={options}
      onChange={(val) => {
        onChange && onChange(val)
        onRangeChange && onRangeChange(UserActions.getRangeOptionsValue(val))
      }}
      value={value}
    />
  );
};

export default SelectDate;