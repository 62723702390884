import React from 'react'
import AppHeader from '../appHeader/AppHeader'
import { Layout } from 'antd'
import 'antd/dist/antd.css'
import Navigation from '../navigation/Navigation'
import './Main.less'
import { connect } from 'react-redux'
import moment from 'moment'
import Filters from '../../components/filters/Filters'
import FilterTagsSection from '../../components/filters/filterTagsSection/FilterTagsSection'
import ContentHolder from '../../components/contentHolder/ContentHolder'
import useWindowSize from '../../utils/useWindowSize'

let { Content } = Layout

let MainLayout = (props) => {
  const { title, hideHeader, screenSize } = props;

  useWindowSize();

  // const isMounted = useIsMounted();

  // useEffect(() => {
  //   const listenerResize = () => {
  //     if (isMounted()) {
  //       let cw = document.body.clientWidth
  //       let screen = 'desktop'
  //       if (cw <= 480) {
  //         screen = 'mobile'
  //       } else if (cw <= 768) {
  //         screen = 'tablet'
  //       }
  //       ApiStorageAction.setScreenSize(screen)
  //     }
  //   }
  //   window.addEventListener('resize', listenerResize)
  //   return () => {
  //     window.removeEventListener('resize', listenerResize)
  //   }
  // }, [isMounted])

  return (
    <div className='main'>
      <Navigation {...props} />
      <Layout className='site-layout'>
        {!hideHeader && <AppHeader title={title} />}
        <Content className='site-layout-background'>
          {screenSize === 'mobile' && <Filters {...props} />}
          <ContentHolder>
            <FilterTagsSection {...props} />
            {React.cloneElement(props.children, { ...props })}
          </ContentHolder>
        </Content>
      </Layout>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { screenSize, filter } = state.rootReducer
  return {
    screenSize: screenSize,
    filter: {
      ...filter,
      dateFrom: moment(filter.dateFrom),
      dateTo: moment(filter.dateTo),
      dateFromP: moment(filter.dateFromP),
      dateToP: moment(filter.dateToP),
    },
  }
}

export default connect(mapStateToProps)(MainLayout)