import React from "react";

const PaymentsIcon = (props) => {
  const { color } = props;
  return (
    <svg
      width='23'
      height='22'
      viewBox='0 0 23 22'
      fill='none'
      className='icon'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9959 7.0813H18.126C15.8598 7.08528 14.0239 8.87532 14.0198 11.085C14.0167 13.2996 15.8547 15.0976 18.126 15.1006H23V15.4524C23 19.3155 20.6581 21.5999 16.6949 21.5999H6.30609C2.34191 21.5999 0 19.3155 0 15.4524V7.03844C0 3.17532 2.34191 0.899902 6.30609 0.899902H16.6908C20.654 0.899902 22.9959 3.17532 22.9959 7.03844V7.0813ZM5.45056 7.07249H11.9366H11.9406H11.9488C12.4344 7.07049 12.8269 6.68478 12.8249 6.21036C12.8228 5.73694 12.4262 5.35422 11.9406 5.35621H5.45056C4.96807 5.3582 4.57656 5.73993 4.57452 6.21136C4.57247 6.68478 4.96501 7.07049 5.45056 7.07249Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M16.1427 11.5911C16.3832 12.685 17.3423 13.4546 18.4372 13.4346H22.1746C22.6302 13.4346 22.9997 13.0573 22.9997 12.5909V9.67963C22.9988 9.21426 22.6302 8.83596 22.1746 8.83496H18.3492C17.1037 8.83896 16.0978 9.87278 16.0997 11.1468C16.0997 11.2959 16.1144 11.445 16.1427 11.5911'
        fill={color}
      />
      <circle cx='18.4' cy='11.1349' r='1.15' fill={color} />
    </svg>
  );
};

export default PaymentsIcon;
PaymentsIcon.defaultProps = {
  color: "black",
};
