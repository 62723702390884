import React from 'react'
import FlagNoimage from '../../../components/icons/FlagNoimage'
import { useTranslation } from 'react-i18next'
import UtilNumber from '../../../utils/utilNumber'
import './OrderListItem.less'
import CompareBlock from '../../../components/compareBlock/CompareBlock'

const OrderListItem = (props) => {
  const { item } = props

  const { t } = useTranslation()
  const flagName =
    (item.flag && require(`../../../Images/Flags/${item.flag.toLowerCase()}`)) || ''

  return (
    <div className='order-list-card'>
      <div className='order-list-wrapper'>
        <div className='order-list-content'>
          <div className='country-flag'>
            {!flagName ? (
              <FlagNoimage />
            ) : (
              <img className='aragich-flag' src={flagName} alt='Araqich' />
            )}
          </div>
          <h1>{t(item.name)}</h1>
        </div>
        <div className='order-list-content order-list-info'>
          <div className='content-info'>
            <h5>{UtilNumber.formatKMB(item.saleCount, t)}</h5>
          </div>
          <div className='content-info payment-info'>
            <h5>
              {t(item.currencyName)}
              {UtilNumber.formatKMB(
                item.saleTotalAmount - item.returnTotalAmount,
                t
              )}
            </h5>
            {item && item.hasOwnProperty('p') && (
              <CompareBlock
                value={item.saleTotalAmount - item.returnTotalAmount}
                oldValue={item.p.saleTotalAmount - item.p.returnTotalAmount}
                currency={item.currencyName}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderListItem
