import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomerActions from '../../actions/customerActions'
import GridBox from '../../components/gridBox/GridBox'
import StatsCard from '../../components/statsCard/StatsCard'
import CustomerBasicChartSection from './CustomerBasicChartSection'
import { notifyError } from '../../utils/notifications'
import TotalCustomersIcon from '../../components/icons/TotalCustomersIcon'
import TotalRoutes from '../../components/icons/TotalRoutes'
import './CustomerPage.less'
import CustomerRouteListSection from './customerRouteListSection/CustomerRouteListSection'
import useIsMounted from '@utilityjs/use-is-mounted'
import CustomerAreaListSectionTabs from './customerAreaListSection/CustomerAreaListSectionTabs'

const defObj = { totalAmount: 0, count: 0, currencyName: '', p: { totalAmount: 0 } }

const CustomerPage = (props) => {
  const { filter } = props

  const { t } = useTranslation()
  const isMounted = useIsMounted()

  const [customerValues, setcustomerValues] = useState(defObj)
  const [routeValues, setrouteValues] = useState(defObj)

  useEffect(() => {
    const fillCus = async () => {
      const resultCustomer = await CustomerActions.countCustomer(filter)
      if (isMounted()) {
        if (resultCustomer.success) {
          setcustomerValues(resultCustomer.data)
        } else {
          notifyError(resultCustomer.errMsg)
        }
      }
    }
    const filR = async () => {
      const resultRout = await CustomerActions.routCustomerCount(filter)
      if (isMounted()) {
        if (resultRout.success) {
          setrouteValues(resultRout.data)
        } else {
          notifyError(resultRout.errMsg)
        }
      }
    }
    if (Object.keys(filter).length > 0) {
      fillCus()
      filR()
    }
  }, [filter, isMounted])

  const renderBlockSection = () => {
    return (
      <GridBox>
        <StatsCard
          icon={<TotalCustomersIcon />}
          title={t('Customers')}
          count={customerValues.count}
        />
        <StatsCard
          icon={<TotalRoutes />}
          title={t('Routs')}
          count={routeValues.count}
        />
      </GridBox>
    )
  }

  return (
    <div className='customersView'>
      {renderBlockSection()}
      <CustomerBasicChartSection filter={filter} {...props} />
      <div className='analyticsInfo'>
        <CustomerAreaListSectionTabs
          filter={filter}
          title={t('Customers by region')}
        />
        <CustomerRouteListSection filter={filter} />
      </div>
    </div>
  )
}

export default CustomerPage
