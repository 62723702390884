import React from 'react'
import { useTranslation } from 'react-i18next'
import Outter from '../outter/Outter'
import UtilNumber from '../../utils/utilNumber'
import './StatsCard.less'
import CompareBlock from '../compareBlock/CompareBlock'
import MyLoadingOutlined from '../myLoadingOutlined/MyLoadingOutlined'

const StatsCard = (props) => {
  const { title, icon, amount, count, currency, oldValue, loading } = props;

  const { t } = useTranslation();

  return (
    <Outter>
      <div className='statsCard'>
        <div className='statsCard__title'>
          {icon && <div className='statsCard__title--icon'>{icon}</div>}
          {props.hasOwnProperty('title') && (
            <div className='statsCard__title--text'>{title}</div>
          )}
        </div>
        {
          loading ?
            <MyLoadingOutlined />
            :
            <>
              <div className='statsCard__amountHolder'>
                {props.hasOwnProperty('amount') && (
                  <div className='statsCard__amountHolder--amount'>
                    <div className='statsCard__amountHolder--amount--label'>
                      {t('AMOUNT')}
                    </div>
                    <div className='statsCard__amountHolder--amount--value'>
                      {currency && t(currency)}
                      {UtilNumber.formatKMB(amount, t)}
                    </div>
                  </div>
                )}
                {props.hasOwnProperty('count') && (
                  <div className='statsCard__amountHolder--amount'>
                    <div className='statsCard__amountHolder--amount--label'>
                      {t('QUANTITY')}
                    </div>
                    <div className='statsCard__amountHolder--amount--value'>
                      {UtilNumber.formatKMB(count, t)}
                    </div>
                  </div>
                )}
              </div>
              {props.hasOwnProperty('oldValue') && (
                <CompareBlock value={amount} oldValue={oldValue} currency={currency} />
              )}
            </>
        }
      </div>
    </Outter>
  )
}

export default StatsCard