import React, { useState, useEffect } from "react";
import UserActions from "../../actions/userActions";
import Select2Input from "../select2Input/Select2Input";
import useIsMounted from "@utilityjs/use-is-mounted";

const SelectRoute = (props) => {

  const { onChange, value, className, label, idsUser } = props;

  const isMounted = useIsMounted();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fill = async () => {
      let list = await UserActions.getRouteListOptions(idsUser);
      if (isMounted()) {
        setOptions(list);
      }
    }
    fill();
  }, [idsUser, isMounted]);

  return (
    <Select2Input
      label={label}
      className={className}
      options={options}
      onChange={onChange}
      value={value}
    />
  );
};

export default SelectRoute;