import React from 'react'
import { Bar } from 'react-chartjs-2'

const BarChart = (props) => {
  const { dataSets, labels, screenSize } = props

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          borderDash: [5, 7],
          drawBorder: false,
          color: 'rgba(118, 118, 118, 0.3)',
        },
      },
    },
  }

  const data = {
    labels: labels,
    datasets: dataSets,
  }

  return <Bar data={data} options={options}
    // height={60} 
    height={screenSize === 'mobile' ? '150' : '50'}
  />
}

export default BarChart
