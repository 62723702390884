import { fetchFromUrl } from "../utils/urlHelper";
import prepareRequestParams from "./prepareRequestParams";
const urlOrderMonthPayment = "/sale_return_payment_by_month_list";
const urlOrderSale = "/sale"
const urlOrderReturn = "/return"
const urlOrderCountryList = "/sale_return_payment_by_country_list"
const urlOrderRegionList = "/sale_return_payment_by_region_list"
const urlOrderCityList = "/sale_return_payment_by_city_list"
const urlOrderDistrictList = "/sale_return_payment_by_district_list";

const repetedDataActions = {
    saleReturnPaymentByMonthList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlOrderMonthPayment, params);
        return result
    },
    sale: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlOrderSale, params)
        return result
    },
    return: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlOrderReturn, params)
        return result
    },
    CountryList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlOrderCountryList, params)
        return result
    },
    RegionList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlOrderRegionList, params)
        return result
    },
    CityList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlOrderCityList, params)
        return result
    },
    DistrictList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlOrderDistrictList, params)
        return result
    },
}
export default repetedDataActions