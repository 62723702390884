import { fetchFromUrl } from "../utils/urlHelper";
import prepareRequestParams from "./prepareRequestParams";

const urlOrderPaymentList = "/order_by_payment_type_list"

const orderActions = {
    paymentList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlOrderPaymentList, params)
        return result
    },
}
export default orderActions
