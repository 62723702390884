import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UtilNumber from '../../utils/utilNumber'

const DivAmount = (props) => {
  const { title, value, per, blue, currency } = props

  const { t } = useTranslation()

  return (
    <div className={`statsCard__amountHolder--amount ${blue && 'amount-wrapper'}`}>
      <div className='statsCard__amountHolder--amount--label label-wrapper'>
        <div className={`value-name ${blue && 'value-name-color'}`}></div>
        {title}
      </div>
      <div className='statsCard__amountHolder--amount--value'>
        {value < 0 ? '-' : ''}
        {t(currency)}
        <span>{UtilNumber.formatKMB(Math.abs(value), t)}</span>
        <span className='percent-value'>
          (
          {(per &&
            per !== Infinity &&
            per !== -Infinity &&
            Math.round(Math.abs(per))) ||
            0}
          %)
        </span>
      </div>
    </div>
  )
}

const DonatChartLabelsSection = (props) => {
  const { valueSale, valueReturn, title1, title2, currency } = props

  const [perSale, setPerSale] = useState(0)
  const [perReturn, setPerReturn] = useState(0)

  const result = parseFloat(valueSale) + parseFloat(valueReturn)

  useEffect(() => {
    let ov = parseFloat(valueReturn)
    let v = parseFloat(valueSale)
    if (v && !ov) {
      setPerSale(100)
      setPerReturn(0)
    } else if (!v && ov) {
      setPerSale(0)
      setPerReturn(100)
    } else if (!v && !ov) {
      setPerSale(0)
      setPerReturn(0)
    } else {
      setPerSale((v * 100) / result)
      setPerReturn((ov * 100) / result)
    }
  }, [valueSale, result, valueReturn])

  // useEffect(() => {
  //   let ov = parseFloat(valueReturn)
  //   let v = parseFloat(valueSale)
  //   let r = (v - ov);
  //   setresult(r)
  //   let res = ((r / ov) * 100)
  //   if (ov && !v) res = Infinity;
  //   setPer(Math.round(Math.abs(res)))
  // }, [valueSalevalueSale, valueReturn, result])

  return (
    <div className='doughnut-chart-info'>
      {/* <div className='statsCard__amountHolder--amount amount-wrapper'>
        <div className='statsCard__amountHolder--amount--label label-wrapper'>
          <div className='value-name value-name-color'></div>
          {title1}
        </div>
        <div className='statsCard__amountHolder--amount--value'>
          <span>{UtilNumber.formatKMB(valueSale, t)}</span>
          <span className='percent-value'>({Math.round(Math.abs(perSale))}%)</span>
        </div>
      </div> */}
      <DivAmount
        title={title1}
        value={valueSale}
        per={perSale}
        blue
        currency={currency}
      />
      <DivAmount
        title={title2}
        value={valueReturn}
        per={perReturn}
        currency={currency}
      />
      {/* <div className='statsCard__amountHolder--amount'>
        <div className='statsCard__amountHolder--amount--label label-wrapper'>
          <div className='value-name '></div>
          {title2}
        </div>
        <div className='statsCard__amountHolder--amount--value'>
          <span>{UtilNumber.formatKMB(valueReturn, t)}</span>
          <span className='percent-value'>({Math.round(Math.abs(perReturn))}%)</span>
        </div>
      </div> */}
    </div>
  )
}

export default DonatChartLabelsSection
