import userActions from '../actions/userActions'
export const TOGGLE = 'TOGGLE'
export const SET_ACCESSTOKEN = 'SET_ACCESSTOKEN'
export const SET_USER = 'SET_USER'
export const SET_PASSWORD = 'SET_PASSWORD'
export const SET_SCREENSIZE = 'SET_SCREENSIZE'
export const SET_FILTER = 'SET_FILTER'
export const SET_LOGOUT = 'SET_LOGOUT'

let defDates = userActions.getRangeOptionsValue(userActions.rangeConst.THIS_MONTH)

const initailState = {
  token: '',
  collapsed: false,
  userInfo: {},
  screenSize: 'mobile',
  filter: {
    dateFrom: defDates.from,
    dateTo: defDates.to,
    selectedDateRangeOption: userActions.rangeConst.THIS_MONTH,
    dateFromP: defDates.fromP,
    dateToP: defDates.toP,
    idsUser: [],
    idsRoute: [],
    idsCustomer: [],
    selectedUsers: [],
    selectedRoutes: [],
    selectedCustomers: [],
  },
}

export const rootReducer = (state = initailState, action) => {
  switch (action.type) {
    case 'TOGGLE':
      return {
        ...state,
        collapsed: action.payload,
      }
    case 'SET_ACCESSTOKEN':
      return {
        ...state,
        token: action.payload,
      }
    case 'SET_SCREENSIZE':
      return {
        ...state,
        screenSize: action.payload,
      }
    case 'SET_USER':
      return {
        ...state,
        filter: initailState.filter,
        userInfo: action.payload,
      }
    case 'SET_FILTER':
      return {
        ...state,
        filter: action.payload,
      }
    case 'SET_LOGOUT':
      return {
        ...state,
        filter: initailState.filter,
        userInfo: initailState.userInfo,
        token: initailState.token,
      }
    default:
      return state
  }
}
