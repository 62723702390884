import { ApiStorageAction } from '../redux/apiStorageAction'
import { fetchFromUrl, fetchFromUrlLogin } from '../utils/urlHelper'
import UtilDate from '../utils/utilDate'

const urlAdminLogin = '/login'
const urlLoginMagic = '/mlogin'
const url_log_out = '/logout'
const url_LIST = '/user_list'
const URL_ROUTE_LIST = '/route_list'

export const selectoptions = {
  COUNTRY: 'Country',
  REGION: 'Region',
  CITY: 'City',
  DISTRICT: 'District',
}

const fs = {
  login: async (login, password) => {
    const params = [
      { key: 'LoginForm[username]', value: login },
      { key: 'LoginForm[password]', value: password },
    ]
    const result = await fetchFromUrlLogin(urlAdminLogin, params)
    if (result.success) {
      await ApiStorageAction.setUser(result.data)
    }
    return result
  },
  loginMagic: async (token) => {
    const params = []
    const result = await fetchFromUrlLogin(urlLoginMagic + '?t=' + token, params)
    if (result.success) {
      await ApiStorageAction.setUser(result.data)
    }
    return result
  },
  logout: async () => {
    const urlParams = []
    await ApiStorageAction.clearUser()
    await fetchFromUrlLogin(url_log_out, urlParams)
  },
  getUserList: async () => {
    const urlParams = []
    const result = await fetchFromUrl(url_LIST, urlParams)
    return result
  },
  getRouteList: async (idsUser) => {
    const urlParams = []
    if (idsUser && idsUser.length) {
      urlParams.push({ key: 'idsUser', value: idsUser })
    }
    const result = await fetchFromUrl(URL_ROUTE_LIST, urlParams)
    return result
  },
}

const loc = {
  rangeConst: {
    YESTERDAY: 'Yesterday',
    TODAY: 'Today',
    LAST_SEVEN_DAYS: 'Last Seven Days',
    THIS_MONTH: 'This Month',
    LAST_MONTH: 'Last Month',
    CUSTOM: 'Custom',
  },
  getSelectOptions: [
    { value: selectoptions.COUNTRY, label: 'Country' },
    { value: selectoptions.REGION, label: 'Region' },
    { value: selectoptions.CITY, label: 'City' },
    { value: selectoptions.DISTRICT, label: 'District' },
  ],
  getRangeOptions: (t) => {
    return [
      { value: userActions.rangeConst.TODAY, label: t('TODAY') },
      { value: userActions.rangeConst.YESTERDAY, label: t('YESTERDAY') },
      { value: userActions.rangeConst.LAST_SEVEN_DAYS, label: t('LAST_SEVEN_DAYS') },
      { value: userActions.rangeConst.THIS_MONTH, label: t('THIS_MONTH') },
      { value: userActions.rangeConst.LAST_MONTH, label: t('LAST_MONTH') },
      { value: userActions.rangeConst.CUSTOM, label: t('CUSTOM') },
    ]
  },
  getRangeOptionsValue: (selectedDateRangeOption) => {
    switch (selectedDateRangeOption) {
      case userActions.rangeConst.YESTERDAY:
        return {
          from: UtilDate.getDateYesterday(),
          to: UtilDate.getDateYesterday(),
          fromP: UtilDate.getSubtractDays(UtilDate.getDateYesterday(), 1),
          toP: UtilDate.getSubtractDays(UtilDate.getDateYesterday(), 1),
        }
      case userActions.rangeConst.LAST_SEVEN_DAYS:
        return {
          from: UtilDate.getLastSevenDays(),
          to: UtilDate.getDateYesterday(),
          fromP: UtilDate.getSubtractDays(UtilDate.getDateToday(), 14),
          toP: UtilDate.getSubtractDays(UtilDate.getDateYesterday(), 7),
        }
      case userActions.rangeConst.THIS_MONTH:
        return {
          from: UtilDate.getThisMonthStart(),
          to: UtilDate.getThisMonthEnd(),
          fromP: UtilDate.getLastMonthStart(),
          toP: UtilDate.getLastMonthEnd(),
        }
      case userActions.rangeConst.LAST_MONTH:
        return {
          from: UtilDate.getLastMonthStart(),
          to: UtilDate.getLastMonthEnd(),
          fromP: UtilDate.getLastMonthStart(UtilDate.getLastMonthStart()),
          toP: UtilDate.getLastMonthEnd(UtilDate.getLastMonthEnd()),
        }
      default:
        return {
          from: UtilDate.getDateToday(),
          to: UtilDate.getDateToday(),
          fromP: UtilDate.getSubtractDays(UtilDate.getDateToday(), 1),
          toP: UtilDate.getSubtractDays(UtilDate.getDateToday(), 1),
        }
    }
  },
  getUserListOptions: async () => {
    let result = await fs.getUserList()

    if (result.success) {
      let list = result.data.map((oo) => {
        return { label: oo.name, value: oo.idUser, ...oo }
      })
      return list
    } else {
      return []
    }
  },
  getRouteListOptions: async (idsUser) => {
    let result = await fs.getRouteList(idsUser)
    if (result.success) {
      return result.data.map((oo) => {
        return { label: oo.name, value: oo.idRoute, ...oo }
      })
    } else {
      return []
    }
  },
}

const userActions = Object.assign(fs, loc)

export default userActions
