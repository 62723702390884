import { useState, useEffect } from 'react'

function useMobileApp() {
  const [isMobileApp, setIsMobileApp] = useState(0)

  useEffect(() => {
    const isMobile = window.location.href.includes('?mobile')
    setIsMobileApp(isMobile)
  }, [])

  return isMobileApp
}

export default useMobileApp
