import React from 'react'
import { Layout } from 'antd'
import 'antd/dist/antd.css'
import Navigation from '../navigation/Navigation'
import './Main.less'
import { connect } from 'react-redux'
import moment from 'moment'
import ContentHolder from '../../components/contentHolder/ContentHolder'
import useWindowSize from '../../utils/useWindowSize'
import AppHeader from '../appHeader/AppHeader'

let { Content } = Layout

let MainSettings = (props) => {
  useWindowSize()

  return (
    <div className='main'>
      <Navigation {...props} hideFilter={true} />
      <Layout className='site-layout'>
        {!props.hideHeader && <AppHeader title={props.title} />}
        <Content className='site-layout-background'>
          <ContentHolder className='settingsContentHolder'>
            {React.cloneElement(props.children, { ...props })}
          </ContentHolder>
        </Content>
      </Layout>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { screenSize, filter } = state.rootReducer
  return {
    screenSize: screenSize,
    filter: {
      ...filter,
      dateFrom: moment(filter.dateFrom),
      dateTo: moment(filter.dateTo),
      dateFromP: moment(filter.dateFromP),
      dateToP: moment(filter.dateFromP),
    },
  }
}

export default connect(mapStateToProps)(MainSettings)
