import React from 'react'

const FilterTag = (props) => {
  const { value, onRemove, className } = props

  return (
    <div className='textFilter'>
      <div className={`textInfo ${className || ''}`}>
        {value.label}
        <span className='close' onClick={() => onRemove && onRemove(value)}></span>
      </div>
    </div>
  )
}

export default FilterTag
