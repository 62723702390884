import React from 'react'

const PaymentCheckedIcon = (props) => {
  const { color } = props
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.4'
        d='M17.0211 2.08301H7.98987C4.45862 2.08301 2.08362 4.56217 2.08362 8.24967V16.7601C2.08362 20.4372 4.45862 22.9163 7.98987 22.9163H17.0211C20.5524 22.9163 22.917 20.4372 22.917 16.7601V8.24967C22.917 4.56217 20.5524 2.08301 17.0211 2.08301Z'
        fill={color}
      />
      <path
        d='M11.264 15.8832C11.0306 15.8832 10.7973 15.7946 10.6192 15.6165L8.14731 13.1446C7.79106 12.7884 7.79106 12.2113 8.14731 11.8561C8.50356 11.4998 9.07961 11.4988 9.43586 11.855L11.264 13.6832L15.564 9.38315C15.9202 9.0269 16.4963 9.0269 16.8525 9.38315C17.2088 9.7394 17.2088 10.3165 16.8525 10.6727L11.9088 15.6165C11.7306 15.7946 11.4973 15.8832 11.264 15.8832Z'
        fill={color}
      />
    </svg>
  )
}

export default PaymentCheckedIcon
PaymentCheckedIcon.defaultProps = {
  color: '#767676',
}
