import React, { useEffect, useState } from 'react'
import GridBox from '../../components/gridBox/GridBox'
import StatsCard from '../../components/statsCard/StatsCard'
import TotalIcon from '../../components/icons/TotalIcon'
import OrdersIcon from '../../components/icons/OrdersIcon'
import ReturnICon from '../../components/icons/ReturnIcon'
import repetedDataActions from '../../actions/repetedDataActions'
// import AreaListSection from '../other/AreaListSection'
import DoughnutChart from '../../components/doughnutChart/DoughnutChart'
import Outter from '../../components/outter/Outter'
import OrderListItem from './orderListItem/OrderListItem'
import { notifyError } from '../../utils/notifications'
import { useTranslation } from 'react-i18next'
import './OrderPage.less'
import OrderBasicChartSection from './OrderBasicChartSection'
import DonatChartLabelsSection from '../../components/doughnutChart/DonatChartLabelsSection'
import useIsMounted from '@utilityjs/use-is-mounted'
import OrderPaymentListSection from './orderPaymentListSection/OrderPaymentListSection'
import AreaListSectionTabs from '../other/AreaListSectionTabs'

const defObj = {
  totalAmount: 0,
  count: 0,
  currencyName: '',
  p: { totalAmount: 0 },
}

const OrderPage = (props) => {
  const { filter } = props

  const { t } = useTranslation()
  const isMounted = useIsMounted()

  const [saleValues, setsaleValues] = useState(defObj)
  const [returnValues, setreturnValues] = useState(defObj)
  const [totalValues, settotalValues] = useState(defObj)
  const [loadingValuesSales, setloadingValuesSales] = useState(false)
  const [loadingValuesReturn, setloadingValuesReturn] = useState(false)

  useEffect(() => {
    const fillSale = async () => {
      setloadingValuesSales(true)
      const resultSale = await repetedDataActions.sale(filter)
      if (isMounted()) {
        if (resultSale.success) {
          setsaleValues(resultSale.data)
        } else {
          notifyError(resultSale.errMsg)
        }
        setloadingValuesSales(false)
      }
    }
    const fillReturn = async () => {
      setloadingValuesReturn(true)
      const resultReturn = await repetedDataActions.return(filter)
      if (isMounted()) {
        if (resultReturn.success) {
          setreturnValues(resultReturn.data)
        } else {
          notifyError(resultReturn.errMsg)
        }
        setloadingValuesReturn(false)
      }
    }
    if (Object.keys(filter).length > 0) {
      fillSale()
      fillReturn()
    }
  }, [filter, isMounted])

  useEffect(() => {
    if (isMounted()) {
      settotalValues({
        totalAmount: saleValues.totalAmount - returnValues.totalAmount,
        count: saleValues.count - returnValues.count,
        currencyName: saleValues.currencyName,
        p: {
          totalAmount: saleValues.p.totalAmount - returnValues.p.totalAmount,
        },
      })
    }
  }, [saleValues, returnValues, isMounted])

  const renderBlockSection = () => {
    return (
      <GridBox>
        <StatsCard
          icon={<OrdersIcon />}
          title={t('ORDERS')}
          amount={saleValues.totalAmount}
          count={saleValues.count}
          currency={saleValues.currencyName}
          oldValue={saleValues.p.totalAmount}
          loading={loadingValuesSales}
        />
        <StatsCard
          icon={<ReturnICon />}
          title={t('RETURNS')}
          amount={returnValues.totalAmount}
          count={returnValues.count}
          currency={returnValues.currencyName}
          oldValue={returnValues.p.totalAmount}
          loading={loadingValuesReturn}
        />
        <StatsCard
          icon={<TotalIcon />}
          title={t('SALES')}
          amount={totalValues.totalAmount}
          currency={totalValues.currencyName}
          oldValue={totalValues.p.totalAmount}
          loading={loadingValuesSales || loadingValuesReturn}
        />
      </GridBox>
    )
  }

  const renderDoughnutChart = () => {
    return (
      <Outter>
        <div className='outter-title'>{t('Orders VS Returns')}</div>
        <div className='doughnut-wrapper'>
          <DonatChartLabelsSection
            title1={t('ORDERS')}
            title2={t('RETURNS')}
            valueSale={saleValues.totalAmount}
            valueReturn={returnValues.totalAmount}
            currency={saleValues.currencyName}
          />
          <DoughnutChart data={[saleValues.totalAmount, returnValues.totalAmount]} />
        </div>
      </Outter>
    )
  }

  return (
    <div className='ordersView'>
      {renderBlockSection()}
      <OrderBasicChartSection {...props} />
      <OrderPaymentListSection
        filter={filter}
        header={
          <div className='order-list-label'>
            {[t('PAYMENT_METHOD'), t('ORDER'), t('AMOUNT')].map((oo, index) => {
              return (
                <div key={index} className={`header-label-${index} payment`}>
                  {oo}
                </div>
              )
            })}
          </div>
        }
      />
      <div className='analyticsInfo'>
        {/* <AreaListSection
          filter={filter}
          header={
            <div className='order-list-label'>
              {[t('NAME'), t('QUANTITY'), t('AMOUNT')].map((oo, index) => {
                return <div key={index}>{oo}</div>
              })}
            </div>
          }
          renderItem={(item, index) => {
            return <OrderListItem item={item} index={index} />
          }}
        /> */}
        <AreaListSectionTabs
          title={t('Orders by region')}
          filter={filter}
          header={
            <div className='order-list-label'>
              {[t('NAME'), t('ORDER'), t('AMOUNT')].map((oo, index) => {
                return (
                  <div key={index} className={`header-label-${index} area`}>
                    {oo}
                  </div>
                )
              })}
            </div>
          }
          renderItem={(item, index) => {
            return <OrderListItem item={item} index={index} />
          }}
        />
        {renderDoughnutChart()}
      </div>
    </div>
  )
}

export default OrderPage
