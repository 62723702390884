import { ApiStorageAction } from "../redux/apiStorageAction"
import React from 'react'
import { Redirect } from 'react-router';

const RdedirectToLoginORMainPage = (props) => {

    let accessToken = ApiStorageAction.getAccessToken()
    return (
        accessToken ?
            <Redirect to="/home" />
            :
            <Redirect to="/login" />
    )
}

export default RdedirectToLoginORMainPage;