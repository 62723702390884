import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectInput from "../selectInput/SelectInput";
import useIsMounted from "@utilityjs/use-is-mounted";

const SelectLanguages = (props) => {
  const { onChange, value, className, label } = props;

  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      setOptions([
        { label: 'English', value: 'en' },
        { label: t('hayeren'), value: 'hy' },
      ]);
    }
  }, [t, isMounted]);

  return (
    <SelectInput
      label={label}
      className={className}
      options={options}
      onChange={onChange}
      value={value}
    />
  );
};

export default SelectLanguages;