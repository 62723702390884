import React from 'react'

const ReturnICon = (props) => {
  const { color } = props
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.4'
        d='M22.9167 18.2845C22.9167 20.8376 20.8019 22.9167 18.2036 22.9167L13.0358 22.9167C10.4429 22.9167 8.33335 20.8438 8.33335 18.2949L8.33335 6.7157C8.33335 4.16154 10.4482 2.08341 13.0475 2.08342L18.2132 2.08342C20.8072 2.08342 22.9167 4.15633 22.9167 6.70529L22.9167 7.68446L22.9167 18.2845Z'
        fill={color}
      />
      <path
        d='M2.31366 13.068L5.27803 16.0982C5.58441 16.4107 6.07745 16.4107 6.38282 16.0961C6.68717 15.7815 6.68615 15.2742 6.38079 14.9617L4.75657 13.3023L6.31383 13.3023L15.0537 13.3023C15.4849 13.3023 15.8349 12.943 15.8349 12.5002C15.8349 12.0565 15.4849 11.6982 15.0537 11.6982L4.75658 11.6982L6.38079 10.0388C6.68615 9.72629 6.68717 9.21899 6.38282 8.90441C6.22963 8.74712 6.02977 8.66795 5.8289 8.66795C5.63006 8.66795 5.4302 8.74712 5.27803 8.90233L2.31366 11.9315C2.16656 12.0825 2.08337 12.2867 2.08337 12.5002C2.08337 12.7127 2.16656 12.9169 2.31366 13.068Z'
        fill={color}
      />
    </svg>
  )
}

export default ReturnICon
ReturnICon.defaultProps = {
  color: '#767676',
}
