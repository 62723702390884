import React from 'react';
import { Select } from "antd";
import "./SelectInput.less"

const SelectInput = (props) => {

    const { options, placeholder, onChange, className, value, label } = props;

    return (
        <div className="my-select-input">
            <label htmlFor={label} >
                {label}
            </label>
            <Select
                className={`select ${className || ""}  `}
                options={options}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
            />
        </div>
    );
}

export default SelectInput;