import React, { useEffect, useState } from 'react'
import { Drawer } from 'antd'
import SelectDate from '../selects/SelectDate'
import DatePickerForm from '../dateForm/DateForm'
import SelectUsers from '../selects/SelectUsers'
import SelectRoute from '../selects/SelectRoute'
import SelectCustomer from '../selects/SelectCustomer'
import userActions from '../../actions/userActions'
import { ApiStorageAction } from '../../redux/apiStorageAction'
import { useTranslation } from 'react-i18next'
import UtilDate from '../../utils/utilDate'
import useIsMounted from '@utilityjs/use-is-mounted'

const FilterDrawer = (props) => {
  const { onChange, visible, onClose, filter, placement, height } = props

  const { t } = useTranslation()
  const isMounted = useIsMounted()

  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState([])
  const [dateFrom, setdateFrom] = useState()
  const [dateTo, setdateTo] = useState()

  const [dateFromP, setdateFromP] = useState()
  const [dateToP, setdateToP] = useState()

  const [idsUser, setidsUser] = useState([])
  const [idsRoute, setidsRoute] = useState([])
  const [idsCustomer, setidsCustomer] = useState([])

  const [selectedUsers, setselectedUsers] = useState([])
  const [selectedRoutes, setselectedRoutes] = useState([])
  const [selectedCustomers, setselectedCustomers] = useState([])

  useEffect(() => {
    if (filter) {
      if (isMounted()) {
        setSelectedDateRangeOption(filter.selectedDateRangeOption)
        setdateFrom(filter.dateFrom)
        setdateTo(filter.dateTo)

        setdateFromP(filter.dateFromP)
        setdateToP(filter.dateToP)

        setidsUser(filter.idsUser)
        setidsRoute(filter.idsRoute)
        setidsCustomer(filter.idsCustomer)

        setselectedUsers(filter.selectedUsers)
        setselectedRoutes(filter.selectedRoutes)
        setselectedCustomers(filter.selectedCustomers)
      }
    }
  }, [filter, isMounted])

  const handleOk = () => {
    let filter = {
      selectedDateRangeOption: selectedDateRangeOption,
      dateFrom: dateFrom,
      dateTo: dateTo,

      dateFromP: dateFromP,
      dateToP: dateToP,

      idsRoute: idsRoute,
      idsCustomer: idsCustomer,
      idsUser: idsUser,

      selectedUsers: selectedUsers,
      selectedRoutes: selectedRoutes,
      selectedCustomers: selectedCustomers,
    }
    ApiStorageAction.setFilter(filter)
    onChange && onChange(filter)
    onClose && onClose(false)
  }

  return (
    <Drawer
      placement={placement}
      closable={false}
      visible={visible}
      onClose={onClose}
      getContainer={false}
      key={'Filter-Drawer'}
      height={height}
      title={t('Filter')}
    >
      <SelectDate
        onChange={setSelectedDateRangeOption}
        value={selectedDateRangeOption}
        className='Select-input'
        onRangeChange={(val) => {
          if (val) {
            setdateFrom(val.from)
            setdateTo(val.to)
            setdateFromP(val.fromP)
            setdateToP(val.toP)
          }
        }}
      />
      <DatePickerForm
        onChange={(dates) => {
          if (dates) {
            setdateFrom(dates[0])
            setdateTo(dates[1])
            let dif = Math.abs(dates[0].diff(dates[1], 'days'))
            setdateFromP(UtilDate.getSubtractDays(dates[0], dif + 1))
            setdateToP(UtilDate.getDateYesterday(dates[0]))
          }
        }}
        value={[dateFrom, dateTo]}
        disabled={selectedDateRangeOption !== userActions.rangeConst.CUSTOM}
      />
      <SelectUsers
        onChange={(ids, objs) => {
          setidsUser(ids)
          setidsRoute([])
          setidsCustomer([])

          setselectedUsers(objs)
          setselectedRoutes([])
          setselectedCustomers([])
        }}
        value={idsUser}
        label={t('Salesperson')}
      />
      <SelectRoute
        onChange={(ids, objs) => {
          setidsRoute(ids)
          setidsCustomer([])

          setselectedRoutes(objs)
          setselectedCustomers([])
        }}
        value={idsRoute}
        idsUser={idsUser}
        label={t('Groups')}
      />
      <SelectCustomer
        onChange={(ids, objs) => {
          setidsCustomer(ids)
          setselectedCustomers(objs)
        }}
        value={idsCustomer}
        idsRoute={idsRoute}
        label={t('Customers')}
      />
      <div className='ant-btn ant-btn-primary' onClick={handleOk}>
        {t('Apply')}
      </div>
    </Drawer>
  )
}

export default FilterDrawer
