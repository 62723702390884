import React from 'react'
import './Navigation.less'
import NavItem from './navItem/NavItem'
import { useTranslation } from 'react-i18next'
// import DashboardIcon from "../../components/icons/DashboardIcon";
import OrdersIcon from '../../components/icons/OrdersIcon'
import PaymentsIcon from '../../components/icons/PaymentsIcon'
import CustomersIcon from '../../components/icons/CustomersIcon'
import { Link } from 'react-router-dom'
import FilterDesktop from '../../components/filters/filterDesktop/FilterDesktop'
import logo from '../../Images/logo.svg'

const deviceSize = ['mobile', 'tablet']

const Navigation = (props) => {
  const { language, filter, screenSize, hideFilter } = props

  const { t } = useTranslation()

  return (
    <div className='filterAndContentWrapper'>
      <div className='sidenav-wrapper'>
        <div className='aside-logo'>
          <Link to='/home'>
            <img src={logo} alt='logo' />
          </Link>
        </div>

        <div className='navigation' language={language}>
          {/* <NavItem
          active
          key='1'
          path='/dashboard'
          label={t("DASHBOARD")}
          icon={<DashboardIcon
            color="#ffffff" />}
        /> */}
          <NavItem
            key='2'
            path='/orders'
            label={t('NAV_ORDERS')}
            icon={<OrdersIcon color='#ffffff' />}
          />
          <NavItem
            key='3'
            path='/payments'
            label={t('NAV_PAYMENTS')}
            icon={<PaymentsIcon color='#ffffff' />}
          />
          <NavItem
            key='4'
            path='/customers'
            label={t('NAV_CUSTOMERS')}
            icon={<CustomersIcon color='#ffffff' />}
          />
        </div>
      </div>
      {!Boolean(deviceSize.includes(screenSize)) && !hideFilter && (
        <FilterDesktop filter={filter} />
      )}
    </div>
  )
}

export default Navigation
