import React, { useState, useEffect } from "react";
import customerActions from "../../actions/customerActions";
import Select2Input from "../select2Input/Select2Input";
import useIsMounted from "@utilityjs/use-is-mounted";

const SelectCustomers = (props) => {

  const { value, className, label, idsRoute, onChange } = props;

  const isMounted = useIsMounted();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fill = async () => {
      let list = await customerActions.getCustomerListOptions(idsRoute);
      if (isMounted()) {
        setOptions(list);
      }
    }
    fill();
  }, [idsRoute, isMounted]);

  return (
    <Select2Input
      label={label}
      className={className}
      options={options}
      onChange={onChange}
      value={value}
    />
  );
};

export default SelectCustomers;