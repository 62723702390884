import React from 'react'
import { useTranslation } from 'react-i18next'
import UtilNumber from '../../../utils/utilNumber'
import './PaymentAreaListItem.less'
import FlagNoimage from '../../../components/icons/FlagNoimage'

const PaymentAreaListItem = (props) => {
  const { item } = props
  const { t } = useTranslation()

  const flagName =
    (item.flag && require(`../../../Images/Flags/${item.flag.toLowerCase()}`)) || ''

  return (
    <div className='order-list-card'>
      <div className='order-list-wrapper'>
        <div className='order-list-content'>
          <div className='country-flag'>
            {!flagName ? (
              <FlagNoimage />
            ) : (
              <img className='aragich-flag' src={flagName} alt='Araqich' />
            )}
          </div>
          <h1>{t(item.name)}</h1>
        </div>
        <div className='order-list-content order-list-info'>
          <div className='content-info '>
            <h5>{UtilNumber.formatKMB(item.paymentCount, t)}</h5>
          </div>
          <div className='content-info payment-info'>
            <h5>
              {t(item.currencyName)}{' '}
              {UtilNumber.formatKMB(item.paymentTotalAmount, t)}
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentAreaListItem
