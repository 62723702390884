import { store } from './combineReducer'
import {
  SET_ACCESSTOKEN,
  SET_FILTER,
  SET_LOGOUT,
  SET_PASSWORD,
  SET_SCREENSIZE,
  SET_USER,
} from './reduxStore'

export const ApiStorageAction = {
  setUser: async (user) => {
    if (user) {
      if (user.hasOwnProperty('password')) {
        await ApiStorageAction.setPassword(user.password)
      }
      if (user.hasOwnProperty('accessToken')) {
        await ApiStorageAction.setAccessToken(user.accessToken)
      }
    }
    await store.dispatch({
      type: SET_FILTER,
      payload: {},
    })
    await store.dispatch({ type: SET_USER, payload: user })
  },
  setAccessToken: async (accessToken) => {
    await store.dispatch({
      type: SET_ACCESSTOKEN,
      payload: accessToken,
    })
  },
  setScreenSize: async (size) => {
    await store.dispatch({
      type: SET_SCREENSIZE,
      payload: size,
    })
  },
  setPassword: async (password) => {
    await store.dispatch({
      type: SET_PASSWORD,
      payload: password,
    })
  },
  getPassword: () => {
    let password = store.getState().rootReducer.userPassword
    return password
  },
  getAccessToken: () => {
    const accessToken = store.getState().rootReducer.token
    return accessToken
  },
  getScreenSize: () => {
    const screenSize = store.getState().rootReducer.screenSize
    return screenSize
  },
  setFilter: async (filter) => {
    await store.dispatch({
      type: SET_FILTER,
      payload: filter,
    })
  },
  getFilter: () => {
    const { filter } = store.getState().rootReducer
    return filter
  },
  clearUser: async () => {
    await store.dispatch({
      type: SET_LOGOUT,
      payload: '',
    })
  },
}
