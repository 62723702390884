import React from 'react'
import { useTranslation } from 'react-i18next'
import FullPage from '../../layouts/pageTypes/FullPage'

const DashboardPage = () => {
  const { t } = useTranslation();
  return (
    <FullPage>
      <h1> {t('DASHBOARD')}</h1>
    </FullPage>
  )
}

export default DashboardPage