import React from 'react'
import Outter from '../../components/outter/Outter'
import { useTranslation } from 'react-i18next'
import { Tabs } from 'antd'
import AreaListSection from './AreaListSection'
import { selectoptions } from '../../actions/userActions'
import './AreaListSectionTabs.less'

const { TabPane } = Tabs

const AreaListSectionTabs = (props) => {
  const { filter, renderItem, header, isPaymentMenu, title } = props

  const { t } = useTranslation()

  return (
    <Outter className='outter-width'>
      <div className='outter-title'>{title}</div>
      <Tabs defaultActiveKey='1' animated>
        <TabPane tab={t('Country')} key='1'>
          <AreaListSection
            filter={filter}
            header={header}
            isPaymentMenu={isPaymentMenu}
            renderItem={renderItem}
            selectedAreaOption={selectoptions.COUNTRY}
          />
        </TabPane>
        <TabPane tab={t('Region')} key='2'>
          <AreaListSection
            filter={filter}
            header={header}
            isPaymentMenu={isPaymentMenu}
            renderItem={renderItem}
            selectedAreaOption={selectoptions.REGION}
          />
        </TabPane>
        <TabPane tab={t('City')} key='3'>
          <AreaListSection
            filter={filter}
            header={header}
            isPaymentMenu={isPaymentMenu}
            renderItem={renderItem}
            selectedAreaOption={selectoptions.CITY}
          />
        </TabPane>
        <TabPane tab={t('District')} key='4'>
          <AreaListSection
            filter={filter}
            header={header}
            isPaymentMenu={isPaymentMenu}
            renderItem={renderItem}
            selectedAreaOption={selectoptions.DISTRICT}
          />
        </TabPane>
      </Tabs>
    </Outter>
  )
}

export default AreaListSectionTabs
