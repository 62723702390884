import React from 'react'

const OrdersIcon = (props) => {
  const { color } = props
  return (
    <svg
      width='26'
      height='27'
      viewBox='0 0 26 27'
      fill='none'
      className='icon'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.4'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.6933 24.4955C19.6933 23.4035 20.5766 22.5195 21.6677 22.5195C22.7459 22.5195 23.6292 23.4035 23.6292 24.4955C23.6292 25.5745 22.7459 26.4585 21.6677 26.4585C20.5766 26.4585 19.6933 25.5745 19.6933 24.4955ZM5.07996 24.4956C5.07996 23.4036 5.96327 22.5196 7.05442 22.5196C8.13258 22.5196 9.01589 23.4036 9.01589 24.4956C9.01589 25.5746 8.13258 26.4586 7.05442 26.4586C5.96327 26.4586 5.07996 25.5746 5.07996 24.4956Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.6295 5.98411C24.4219 5.98411 24.9415 6.25711 25.4611 6.85511C25.9807 7.45311 26.0716 8.31111 25.9547 9.08981L24.7207 17.6178C24.4869 19.2571 23.084 20.4648 21.4343 20.4648H7.2623C5.53465 20.4648 4.10576 19.1388 3.96288 17.4241L2.76781 3.25281L0.806336 2.91481C0.286741 2.82381 -0.0769752 2.31681 0.0139539 1.79681C0.104883 1.26381 0.611488 0.91281 1.14407 0.99081L4.24216 1.45881C4.68381 1.53811 5.00856 1.90081 5.04753 2.34281L5.29434 5.25481C5.33331 5.67211 5.67104 5.98411 6.08672 5.98411H23.6295ZM15.7577 12.7428H19.3559C19.9015 12.7428 20.3301 12.3008 20.3301 11.7678C20.3301 11.2218 19.9015 10.7928 19.3559 10.7928H15.7577C15.2121 10.7928 14.7835 11.2218 14.7835 11.7678C14.7835 12.3008 15.2121 12.7428 15.7577 12.7428Z'
        fill={color}
      />
    </svg>
  )
}

export default OrdersIcon
OrdersIcon.defaultProps = {
  color: '#767676',
}
