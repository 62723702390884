import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UtilNumber from '../../utils/utilNumber'
import './CompareBlock.less'

const CompareBlock = props => {
  const { value, oldValue, currency } = props

  const [per, setPer] = useState(0)
  const [result, setresult] = useState(0)

  const { t } = useTranslation();

  useEffect(() => {
    let ov = parseFloat(oldValue)
    let v = parseFloat(value)
    let r = (v - ov);
    setresult(r)
    let res = ((r / ov) * 100)
    if (ov && !v) res = Infinity;
    setPer(Math.round(Math.abs(res)))
  }, [value, oldValue, result])

  return (
    <div
      className={`compareValue ${result > 0
        ? 'compareValue__positive'
        : result < 0
          ? 'compareValue__negative'
          : ''
        }`}
    >
      {result > 0 ? '+' : result < 0 ? '-' : ''}

      {/* {UtilNumber.formatKMB(Math.abs(result))} ({(per === Infinity || Number.isNaN(per)) ? '∞' : per}%) */}
      {/* {UtilNumber.formatKMB(Math.abs(oldValue))} ({Number.isNaN(per) ? 0 : (per === Infinity) ? '∞' : per + '%'}) */}
      {Number.isNaN(per) ? 0 : (per === Infinity) ? '∞' : per + '%'} ({t(currency)}{UtilNumber.formatKMB(Math.abs(oldValue), t)})
    </div>
  )
}

CompareBlock.defaultProps = {
  oldValue: 0,
  value: 0,
  currency: ''
}

export default CompareBlock