import React from 'react'
import './DoughnutChart.less'
import { Doughnut } from 'react-chartjs-2'
import NoData from '../noData/NoData'

const DoughnutChart = (props) => {
  const { data, className } = props

  return (
    <div className={`doughnut-chart ${className || ''}`}>
      {Boolean(
        data?.length &&
          data.filter((val) => {
            return parseFloat(val) > 0
          }).length
      ) ? (
        <Doughnut
          data={{
            datasets: [
              {
                label: '# of Votes',
                data: data,
                backgroundColor: ['#5861f7', '#ff9e34'],
                borderColor: ['#5861f7', '#ff9e34'],
                borderWidth: 1,
              },
            ],
          }}
          options={{ responsive: true, maintainAspectRatio: false }}
          width={60}
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default DoughnutChart
