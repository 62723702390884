import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import DashboardPage from '../pages/dashboard/DashboardPage'
import { useTranslation } from 'react-i18next'
import PrivateRoute from './privateRoute'
// import ErrorPage from '../pages/errorPage/ErrorPage'
import Settings from '../pages/settings/Settings'
import MainLayout from '../layouts/main/Main'
import CustomerPage from '../pages/customers/CustomerPage'
import OrderPage from '../pages/orders/OrderPage'
import PaymentPage from '../pages/payments/PaymentPage'
import Login from '../pages/login/Login'
import MagicLogin from '../redirects/MagicLogin'
import MainSettings from '../layouts/main/MainSettings'
import RdedirectToLoginORMainPage from './RdedirectToLoginORMainPage'
import LoginRoute from './LoginRoute'

const Router = () => {
  const { t } = useTranslation()

  return (
    <Switch>
      <LoginRoute
        path='/login'
        exact
        render={() => {
          return <Login />
        }}
      />
      <Route path='/magic_login/:_token' exact component={MagicLogin} />
      <PrivateRoute
        path='/dashboard'
        exact
        render={() => {
          return (
            <MainLayout title={t('DASHBOARD')}>
              <DashboardPage />
            </MainLayout>
          )
        }}
      />
      <PrivateRoute
        path='/home'
        exact
        render={() => {
          return <Redirect to='/orders' />
        }}
      />
      <PrivateRoute
        path='/orders'
        exact
        render={() => {
          return (
            <MainLayout title={t('ORDERS')}>
              <OrderPage />
            </MainLayout>
          )
        }}
      />
      <PrivateRoute
        path='/payments'
        exact
        render={() => {
          return (
            <MainLayout title={t('PAYMENTS')}>
              <PaymentPage />
            </MainLayout>
          )
        }}
      />
      <PrivateRoute
        path='/customers'
        exact
        render={() => {
          return (
            <MainLayout title={t('CUSTOMERS')}>
              <CustomerPage />
            </MainLayout>
          )
        }}
      />
      <PrivateRoute
        path='/settings'
        exact
        render={() => {
          return (
            <MainSettings title={t('SETTINGS')} hideFilter>
              <Settings />
            </MainSettings>
          )
        }}
      />
      {/* <Route component={ErrorPage} /> */}
      <RdedirectToLoginORMainPage />
    </Switch>
  )
}

export default Router
