import React from 'react'
import { Redirect, Route } from 'react-router'
import { ApiStorageAction } from '../redux/apiStorageAction'

const LoginRoute = (props) => {
  const { exact, path, render } = props

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        let accessToken = ApiStorageAction.getAccessToken()
        return !accessToken ? render() : <Redirect to='/home' replace={true} />
      }}
    />
  )
}

export default LoginRoute
