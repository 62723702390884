import React from 'react'

const TotalCustomersIcon = (props) => {
  const { color } = props
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.73881 15.4768C5.60984 15.4768 2.08325 16.1146 2.08325 18.6639C2.08325 21.2152 5.58742 21.875 9.73881 21.875C13.8678 21.875 17.3944 21.2372 17.3944 18.6879C17.3944 16.1366 13.8902 15.4768 9.73881 15.4768Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M9.73875 13.0467C12.5508 13.0467 14.8044 10.8398 14.8044 8.08584C14.8044 5.33193 12.5508 3.125 9.73875 3.125C6.92767 3.125 4.6731 5.33193 4.6731 8.08584C4.6731 10.8398 6.92767 13.0467 9.73875 13.0467Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M16.8473 8.17582C16.8473 9.57823 16.4172 10.8868 15.6629 11.9738C15.5845 12.0856 15.6538 12.2363 15.7904 12.2603C15.9799 12.2912 16.1746 12.3102 16.3734 12.3142C18.3507 12.3651 20.1252 11.1184 20.6155 9.24086C21.3422 6.455 19.2099 3.95361 16.4936 3.95361C16.1991 3.95361 15.9167 3.98356 15.6415 4.03846C15.6038 4.04644 15.5631 4.06441 15.5427 4.09635C15.5162 4.13727 15.5355 4.19018 15.562 4.22511C16.3785 5.34604 16.8473 6.71053 16.8473 8.17582Z'
        fill={color}
      />
      <path
        d='M22.6865 15.8014C22.3246 15.0458 21.4511 14.5278 20.122 14.2732C19.4952 14.1235 17.7971 13.9109 16.2183 13.9408C16.1949 13.9438 16.1816 13.9598 16.1796 13.9698C16.1765 13.9858 16.1837 14.0097 16.2143 14.0267C16.944 14.38 19.7643 15.9172 19.4096 19.1592C19.3943 19.3009 19.5095 19.4207 19.6532 19.4008C20.3473 19.3039 22.133 18.9276 22.6865 17.7578C22.9933 17.1389 22.9933 16.4203 22.6865 15.8014Z'
        fill={color}
      />
    </svg>
  )
}

export default TotalCustomersIcon
TotalCustomersIcon.defaultProps = {
  color: '#767676',
}
