import React from 'react';
import { useTranslation } from 'react-i18next';
import "./CustomerRouteListItem.less";

const CustomerRouteListItem = (props) => {
    const { item } = props;

    const { t } = useTranslation();
    
    return (
        <div className="customer-block-vertical">
            <div className="name-wrapper">
                <h5>{t(item.name)}</h5>
            </div>
            <div>
                <h5>{t(item.count)}</h5>
            </div>
        </div >
    );
}

export default CustomerRouteListItem;