import React, { useState } from 'react';
import { Select } from "antd";
import "./Select2Input.less"

const { Option } = Select;

const Select2Input = (props) => {

    const { options, placeholder, className, value, label, onChange } = props;

    const [open, setopen] = useState(false);

    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div className="my-select-input my-select2">
            <label htmlFor={label} >
                {label}
            </label>
            <Select
                className={`select-input ${className || ""}  `}
                optionLabelProp="label"
                style={{ width: "100%" }}
                placeholder={placeholder}
                open={open}
                onBlur={() => setopen(false)}
                onClick={() => !open && setopen(true)}
                onSelect={() => setopen(false)}
                onDeselect={() => setopen(false)}
                onChange={onChange}
                value={value}
                mode="multiple"
                onMouseDown={onPreventMouseDown}
            >
                {options.map((item, index) => {
                    return (
                        <Option key={index} value={item.value} label={item.label}>
                            <div>
                                {item.label}
                            </div>
                        </Option>
                    )
                })}
            </Select>
        </div>
    );
}

export default Select2Input;