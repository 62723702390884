import { fetchFromUrl } from "../utils/urlHelper";
import prepareRequestParams from "./prepareRequestParams";

const urlPayment = "/payment"
const urlOrderPaymentList = "/payment_by_payment_type_list"

const paymentActions = {
    paymentObject: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlPayment, params)
        return result
    },
    paymentList: async (filter) => {
        const params = prepareRequestParams(filter);
        const result = await fetchFromUrl(urlOrderPaymentList, params)
        return result
    },
}
export default paymentActions
