import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppList from '../../../components/appList/AppList'
import { notifyError } from '../../../utils/notifications'
import PaymentListItem from '../../other/paymentListSection/PaymentListItem'
import Outter from '../../../components/outter/Outter'
import paymentActions from '../../../actions/paymentActions'
import useIsMounted from '@utilityjs/use-is-mounted'

const PaymentListSection = (props) => {
  const { filter, header } = props

  const { t } = useTranslation()
  const isMounted = useIsMounted()

  const [listData, setlistData] = useState([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    const fill = async () => {
      if (Object.keys(filter).length > 0) {
        setloading(true)
        const result = await paymentActions.paymentList(filter)
        if (isMounted()) {
          if (result.success) {
            setlistData(result.data)
          } else {
            notifyError(result.errMsg)
          }
          setloading(false)
        }
      }
    }
    fill()
  }, [filter, isMounted])

  return (
    <Outter>
      <div className='outter-title'>{t('Payments by payment method')}</div>
      <AppList
        dataSource={listData}
        loading={loading}
        renderItem={(item, index) => {
          return <PaymentListItem item={item} index={index} />
        }}
        header={header}
      />
    </Outter>
  )
}

export default PaymentListSection
