import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs } from 'antd'
import { selectoptions } from '../../../actions/userActions'
import CustomerAreaListSection from './CustomerAreaListSection'
import Outter from '../../../components/outter/Outter'

const { TabPane } = Tabs

const CustomerAreaListSectionTabs = (props) => {
  const { filter, renderItem, header, title } = props

  const { t } = useTranslation()

  return (
    <Outter className='outter-width'>
      <div className='outter-title'>{title}</div>
      <Tabs
        defaultActiveKey='1'
        // centered
        animated
      >
        <TabPane tab={t('Country')} key='1'>
          <CustomerAreaListSection
            filter={filter}
            header={header}
            renderItem={renderItem}
            selectedAreaOption={selectoptions.COUNTRY}
          />
        </TabPane>
        <TabPane tab={t('Region')} key='2'>
          <CustomerAreaListSection
            filter={filter}
            header={header}
            renderItem={renderItem}
            selectedAreaOption={selectoptions.REGION}
          />
        </TabPane>
        <TabPane tab={t('City')} key='3'>
          <CustomerAreaListSection
            filter={filter}
            header={header}
            renderItem={renderItem}
            selectedAreaOption={selectoptions.CITY}
          />
        </TabPane>
        <TabPane tab={t('District')} key='4'>
          <CustomerAreaListSection
            filter={filter}
            header={header}
            renderItem={renderItem}
            selectedAreaOption={selectoptions.DISTRICT}
          />
        </TabPane>
      </Tabs>
    </Outter>
  )
}

export default CustomerAreaListSectionTabs
