import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SelectLanguages from '../../components/selects/SelectLanguages'
import ReturnICon from '../../components/icons/ReturnIcon'
import './Settings.less'
import userActions from '../../actions/userActions'

const Settings = () => {

  const { t, i18n } = useTranslation();


  return (
    <div className='settingsPage'>
      <div className='outter'>
        <div className='outter__inner'>
          <div className='settingsItem'>
            <span className='settingItem--label'>{t('Language')}</span>
            <SelectLanguages
              value={i18n.language}
              onChange={(lng) => {
                i18n.changeLanguage(lng)
              }}
            />
          </div>
          <div className='settingsItem logoutWrapper' onClick={() => {
            userActions.logout()
          }}>
            <Link className='logout-link' to='/login'>
              <ReturnICon color='#E24747' />
              {t('Log_OUT')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings