import React from 'react'
import { useTranslation } from 'react-i18next'
import { ApiStorageAction } from '../../../redux/apiStorageAction'
import appStoreBadge from '../assets/app-store-badge.png'
import playStoreBadge from '../assets/play-store-badge.png'
import android from '../assets/android.svg'
import ios from '../assets/ios.svg'
import './DownloadMobileAppBadges.less'

const DownloadMobileAppBadges = () => {
  const { t } = useTranslation()

  const screenSize = ApiStorageAction.getScreenSize()

  return (
    <div className='mobile-app-list__wrapper'>
      <div className='mobile-app-list__wrapper--title'>
        {t('WORD_DOWNLOAD_ANALYTICS_APP')}
      </div>
      {screenSize === 'desktop' && (
        <div className='mobile-app-list__wrapper--badges--withQR'>
          <img src={ios} alt='ios-qr' />
          <img src={android} alt='android-qr' />
        </div>
      )}
      <div className='mobile-app-list__wrapper--badges'>
        <a
          href='https://apps.apple.com/am/app/araqich-analytics/id1609453680'
          rel='noopener noreferrer'
          target='_blank'
        >
          <img src={appStoreBadge} alt='App store badge' />
        </a>
        <a
          href='https://play.google.com/store/apps/details?id=com.araqich.analytics'
          rel='noopener noreferrer'
          target='_blank'
        >
          <img src={playStoreBadge} alt='Play store badge' />
        </a>
      </div>
    </div>
  )
}

export default DownloadMobileAppBadges
