import React from 'react'
import { ApiStorageAction } from '../../../redux/apiStorageAction'
import FilterTag from '../FilterTag'

const FilterTagsSection = (props) => {
  const { filter } = props

  const handleRemoveUser = (value) => {
    let filterMain = ApiStorageAction.getFilter()
    let filter = {
      ...filterMain,
      selectedUsers: filterMain.selectedUsers.filter((it) => {
        return it.value !== value.value
      }),
      idsUser: filterMain.idsUser.filter((it) => {
        return it !== value.value
      }),
      selectedRoutes: [],
      idsRoute: [],
      selectedCustomers: [],
      idsCustomer: [],
    }
    ApiStorageAction.setFilter(filter)
  }

  const handleRemoveRoute = (value) => {
    let filterMain = ApiStorageAction.getFilter()
    let filter = {
      ...filterMain,
      selectedRoutes: filterMain.selectedRoutes.filter((it) => {
        return it.value !== value.value
      }),
      idsRoute: filterMain.idsRoute.filter((it) => {
        return it !== value.value
      }),
      selectedCustomers: [],
      idsCustomer: [],
    }
    ApiStorageAction.setFilter(filter)
  }

  const handleRemoveCustomer = (value) => {
    let filterMain = ApiStorageAction.getFilter()
    let filter = {
      ...filterMain,
      selectedCustomers: filterMain.selectedCustomers.filter((it) => {
        return it.value !== value.value
      }),
      idsCustomer: filterMain.idsCustomer.filter((it) => {
        return it !== value.value
      }),
    }
    ApiStorageAction.setFilter(filter)
  }

  return (
    <div className='textWrapper'>
      {Boolean(filter && filter.selectedUsers) &&
        filter.selectedUsers.map((oo, index) => {
          return (
            <FilterTag
              key={index}
              className='tag__user'
              value={oo}
              onRemove={handleRemoveUser}
            />
          )
        })}
      {Boolean(filter && filter.selectedRoutes) &&
        filter.selectedRoutes.map((oo, index) => {
          return (
            <FilterTag
              key={index}
              className='tag__route'
              value={oo}
              onRemove={handleRemoveRoute}
            />
          )
        })}
      {Boolean(filter && filter.selectedCustomers) &&
        filter.selectedCustomers.map((oo, index) => {
          return (
            <FilterTag
              key={index}
              className='tag__customer'
              value={oo}
              onRemove={handleRemoveCustomer}
            />
          )
        })}
    </div>
  )
}

export default FilterTagsSection
