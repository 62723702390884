import React from 'react'
import Lottie from 'react-lottie'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import ButtonPrimary from '../../components/buttons/Buttons'
import PageEdit from '../../layouts/pageTypes/PageEdit'
import { ApiStorageAction } from '../../redux/apiStorageAction'
import { notifyError } from '../../utils/notifications'
import useMobileApp from '../../utils/useMobileApp'
import UserActions from '../../actions/userActions'
import FormContainer from '../../components/formContainer/FormContainer'
import InputFormText from '../../components/form/inputFormText/InputFormText'
import InputFormPassword from '../../components/form/inputFormPassword/InputFormPassword'
import DownloadMobileAppBadges from './downloadMobileAppBadges/DownloadMobileAppBadges'
import animationData from './assets/AnalyticsSplash.json'
import './Login.less'

const Login = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const isMobileApp = useMobileApp()

  const onFinish = async (values) => {
    const result = await UserActions.login(values.email, values.password)
    if (result.success) {
      ApiStorageAction.setAccessToken(result.data.ACCESS_TOKEN)
      history.push('/home')
    } else {
      notifyError(result.errMsg)
    }
  }

  const initialValues = {
    email: '',
    password: '',
  }

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <PageEdit>
      <div className='login-page'>
        <Lottie options={defaultOptions} height={200} width={200} />
        <FormContainer
          initialValues={initialValues}
          name='login'
          onFinish={onFinish}
        >
          <InputFormText
            placeholder={t('LOGIN_INPUT_PLACEHOLDER')}
            name='email'
            required
          />
          <InputFormPassword
            placeholder={t('PASSWORD_INPUT_PLACEHOLDER')}
            name='password'
            required
          />
          <ButtonPrimary type='primary' label={t('Sign_In')} htmlType='submit' />
        </FormContainer>
        <div className='newUserWrapper'>
          <span>{t('Dont have an account yet?')}</span>
          <a href='https://araqich.com' target='_blank' rel='noopener noreferrer'>
            {t('Register')}
          </a>
        </div>
      </div>
      {!isMobileApp && <DownloadMobileAppBadges />}
    </PageEdit>
  )
}

export default Login
