import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import repetedDataActions from '../../actions/repetedDataActions'
import GridBox from '../../components/gridBox/GridBox'
import SaleIcon from '../../components/icons/SaleIcon'
import StatsCard from '../../components/statsCard/StatsCard'
import PaymentCheckedIcon from '../../components/icons/PaymentCheckedIcon'
import DebtIcon from '../../components/icons/DebtIcon'
import PaymentListSection from './paymentListSection/PaymentListSection'
import PaymentActions from '../../actions/paymentActions'
import PaymentBarChartSection from './PaymentBarChartSection'
// import AreaListSection from '../other/AreaListSection'
import Outter from '../../components/outter/Outter'
import './PaymentPage.less'
import { notifyError } from '../../utils/notifications'
import DoughnutChart from '../../components/doughnutChart/DoughnutChart'
import PaymentAreaListItem from './paymentAreaListItem/PaymentAreaListItem'
import DonatChartLabelsSection from '../../components/doughnutChart/DonatChartLabelsSection'
import useIsMounted from '@utilityjs/use-is-mounted'
import AreaListSectionTabs from '../other/AreaListSectionTabs'

const defObj = {
  totalAmount: 0,
  count: 0,
  currencyName: '',
  p: { totalAmount: 0 },
}

const PaymentPage = (props) => {
  const { filter } = props

  const { t } = useTranslation()
  const isMounted = useIsMounted()

  const [saleNetValues, setsaleNetValues] = useState(defObj)
  const [paymentsValues, setpaymentsValues] = useState(defObj)

  const [loadingValuesSales, setloadingValuesSales] = useState(false)
  const [loadingValuesPayments, setloadingValuesPayments] = useState(false)

  useEffect(() => {
    const fill = async () => {
      if (Object.keys(filter).length > 0) {
        setloadingValuesSales(true)
        const resultSale = await repetedDataActions.sale(filter)
        if (isMounted()) {
          if (!resultSale.success) {
            notifyError(resultSale.errMsg)
          }
        }
        const resultReturn = await repetedDataActions.return(filter)
        if (isMounted()) {
          if (!resultReturn.success) {
            notifyError(resultReturn.errMsg)
          }
        }
        if (isMounted()) {
          if (resultSale.success && resultReturn.success) {
            setsaleNetValues({
              totalAmount:
                resultSale.data.totalAmount - resultReturn.data.totalAmount,
              count: resultSale.data.count - resultReturn.data.count,
              currencyName: resultSale.data.currencyName,
              p: {
                totalAmount:
                  resultSale.data.p.totalAmount - resultReturn.data.p.totalAmount,
              },
            })
          }
          setloadingValuesSales(false)
        }
      }
    }
    fill()
  }, [filter, isMounted])

  useEffect(() => {
    const fill = async () => {
      if (Object.keys(filter).length > 0) {
        setloadingValuesPayments(true)
        const result = await PaymentActions.paymentObject(filter)
        if (isMounted()) {
          if (result.success) {
            setpaymentsValues(result.data)
          } else {
            notifyError(result.errMsg)
          }
          setloadingValuesPayments(false)
        }
      }
    }
    fill()
  }, [filter, isMounted])

  const renderBlockSection = () => {
    return (
      <GridBox>
        <StatsCard
          icon={<SaleIcon />}
          title={t('SALES')}
          amount={saleNetValues.totalAmount}
          count={saleNetValues.count}
          currency={saleNetValues.currencyName}
          oldValue={saleNetValues.p.totalAmount}
          loading={loadingValuesSales}
        />
        <StatsCard
          icon={<PaymentCheckedIcon />}
          title={t('PAYMENTS')}
          amount={paymentsValues.totalAmount}
          count={paymentsValues.count}
          currency={paymentsValues.currencyName}
          oldValue={paymentsValues.p.totalAmount}
          loading={loadingValuesPayments}
        />
        <StatsCard
          icon={<DebtIcon />}
          title={t('DEBTS')}
          amount={saleNetValues.totalAmount - paymentsValues.totalAmount}
          currency={saleNetValues.currencyName}
          oldValue={saleNetValues.p.totalAmount - paymentsValues.p.totalAmount}
          loading={loadingValuesSales || loadingValuesPayments}
        />
      </GridBox>
    )
  }

  const renderDoughnutChart = () => {
    return (
      <Outter>
        <div className='outter-title'>{t('Payments VS Debts')}</div>
        <div className='doughnut-wrapper'>
          <DonatChartLabelsSection
            title1={t('PAYMENTS')}
            title2={t('DEBTS')}
            valueSale={saleNetValues.totalAmount - paymentsValues.totalAmount}
            valueReturn={paymentsValues.totalAmount}
            currency={saleNetValues.currencyName}
          />
          <DoughnutChart
            data={[
              saleNetValues.totalAmount - paymentsValues.totalAmount,
              paymentsValues.totalAmount,
            ]}
          />
        </div>
      </Outter>
    )
  }

  return (
    <div className='paymentsView'>
      {renderBlockSection()}
      <PaymentListSection
        filter={filter}
        header={
          <div className='order-list-label'>
            {[t('NAME'), t('AMOUNT'), t('QUANTITY')].map((oo, index) => {
              return (
                <div key={index} className={`header-label-${index}`}>
                  {oo}
                </div>
              )
            })}
          </div>
        }
      />
      <PaymentBarChartSection filter={filter} {...props} />
      <div className='analitics-info'>
        {/* <AreaListSection
          filter={filter}
          header={
            <div className='order-list-label'>
              {[t('NAME'), t('QUANTITY'), t('AMOUNT')].map((oo, index) => {
                return <div key={index} className={`header-label-${index}`}>
                  {oo}
                </div>
              })}
            </div>
          }
          renderItem={(item) => {
            return <PaymentAreaListItem item={item} />
          }}
        /> */}
        <AreaListSectionTabs
          title={t('Payments by region')}
          filter={filter}
          isPaymentMenu
          header={
            <div className='order-list-label'>
              {[t('NAME'), t('QUANTITY'), t('AMOUNT')].map((oo, index) => {
                return (
                  <div key={index} className={`header-label-${index} area`}>
                    {oo}
                  </div>
                )
              })}
            </div>
          }
          renderItem={(item, index) => {
            return <PaymentAreaListItem item={item} index={index} />
          }}
        />
        {renderDoughnutChart()}
      </div>
    </div>
  )
}

export default PaymentPage
