import useIsMounted from "@utilityjs/use-is-mounted";
import { useState, useEffect } from "react";
import { ApiStorageAction } from "../redux/apiStorageAction";

const useWindowSize = () => {


    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    const isMounted = useIsMounted();


    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            if (isMounted()) {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });

                let cw = window.innerWidth;
                let screen = 'desktop'
                if (cw <= 480) {
                    screen = 'mobile'
                } else if (cw <= 768) {
                    screen = 'tablet'
                }
                ApiStorageAction.setScreenSize(screen)
            }
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [isMounted]);
    return windowSize;
}

export default useWindowSize;