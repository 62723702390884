
const prepareRequestParams = (filter) => {
    const params = [];
    if (filter) {
        if (filter.dateFrom) {
            params.push({ key: "dateFrom", value: filter.dateFrom })
        }
        if (filter.dateTo) {
            params.push({ key: "dateTo", value: filter.dateTo })
        }
        if (filter.idsUser) {
            params.push({ key: "idsUser", value: filter.idsUser })
        }
        if (filter.idsRoute) {
            params.push({ key: "idsRoute", value: filter.idsRoute })
        }
        if (filter.idsCustomer) {
            params.push({ key: "idsCustomer", value: filter.idsCustomer })
        }
        if (filter.dateFromP && filter.dateToP) {
            params.push(
                { key: "p_dateFrom", value: filter.dateFromP },
                { key: "p_dateTo", value: filter.dateToP }
            )
        }
    }
    return params;
}

export default prepareRequestParams;