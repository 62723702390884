import React, { useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next'
import repetedDataActions from '../../actions/repetedDataActions'
import { selectoptions } from '../../actions/userActions'
import AppList from '../../components/appList/AppList'
// import Outter from '../../components/outter/Outter'
// import SelectCountry from '../../components/selects/SelectCountry'
import { notifyError } from '../../utils/notifications'
import useIsMounted from "@utilityjs/use-is-mounted";

const AreaListSection = (props) => {
  const { filter, renderItem, header, selectedAreaOption,
    // isPaymentMenu
  } = props

  // const { t } = useTranslation();
  const isMounted = useIsMounted();

  // const [selectedAreaOption, setSelectedAreaOption] = useState(selectoptions.COUNTRY)
  const [listData, setlistData] = useState([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    const selectArea = async () => {
      if (Object.keys(filter).length > 0) {
        let result
        let filterLocal = Object.assign({}, filter)
        delete filterLocal.p_dateFrom
        delete filterLocal.p_dateTo
        setloading(true);
        switch (selectedAreaOption) {
          case selectoptions.COUNTRY:
            result = await repetedDataActions.CountryList(filter)
            break
          case selectoptions.REGION:
            result = await repetedDataActions.RegionList(filter)
            break
          case selectoptions.CITY:
            result = await repetedDataActions.CityList(filter)
            break
          case selectoptions.DISTRICT:
            result = await repetedDataActions.DistrictList(filter)
            break
          default:
            break
        }
        // let fil = result.data.filter((oo) => {
        //   return isPaymentMenu ? (oo.paymentCount) : (oo.saleCount || oo.returnCount)
        // })

        let fil = result.data;
        if (isMounted() && result) {
          if (result.success) {
            setlistData(fil);
          } else {
            notifyError(result.errMsg);
          }
          setloading(false);
        }
      }
    }
    selectArea()
  }, [selectedAreaOption, filter, isMounted])

  return (
    <div className='div-list-chart'>
      <div className='section-list-dougnutChart'>
        <AppList
          dataSource={listData}
          loading={loading}
          renderItem={renderItem}
          header={header}
        />
      </div>
    </div>
  )
}

export default AreaListSection